<script setup lang="ts">
interface Props {
  isLocked?: boolean
  border?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isLocked: false,
  border: true,
})

const isOpen = defineModel('isOpen', { type: Boolean, default: true })

const toggle = () => {
  isOpen.value = !isOpen.value
}
</script>

<template>
  <section :class="{ 'rounded border border-gray-200 bg-white': props.border }">
    <header
      :class="[isOpen ? 'rounded-t' : 'rounded', { 'p-4 md:py-5 md:px-6': props.border }]"
      class="focus-within:ring-peacock-300 relative rounded-t focus-within:ring-1"
    >
      <div class="flex items-center justify-between space-x-6">
        <slot name="leftAction" />

        <div>
          <h2
            v-if="$slots.title"
            :class="[props.border ? 'flex flex-row space-x-3' : 'flex flex-col space-y-3']"
            class="text-lg font-semibold leading-tight"
          >
            <div v-if="$slots.titleIcon">
              <slot name="titleIcon" />
            </div>
            <span class="font-semibold"><slot name="title" /></span>
          </h2>

          <div
            v-if="$slots.description"
            class="mt-2"
          >
            <slot name="description" />
          </div>
        </div>

        <button
          v-if="!props.isLocked"
          type="button"
          @click="toggle()"
        >
          <span
            class="absolute inset-0"
            aria-hidden="true"
          />
          <span class="sr-only">{{ isOpen ? $t('commons.actions.reduce') : $t('commons.actions.extend') }}</span>
          <YscIconsArrowBottom
            :class="{ 'rotate-180': isOpen }"
            class="h-6 w-6 flex-none text-gray-500 transition-transform duration-200"
          />
        </button>
      </div>
    </header>

    <div
      v-show="isOpen"
      :class="{ 'border-t border-gray-200 px-4 md:px-6': props.border }"
    >
      <slot />
    </div>
  </section>
</template>
